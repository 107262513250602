body {
  margin: 0;
}

.fontSize_11px {
  font-size: 11px !important;
}

.canvasjs-chart-credit{
  display: none;
  }